import {
  ApiTypesV1GroupResponse,
  GroupWrapper,
  isAdmin,
  isJoined,
} from '@wix/social-groups-api';
import { IGroup } from '../types/IGroup';

// TODO: V2
export const useGroupWrapper = (group: ApiTypesV1GroupResponse) => {
  const g = new GroupWrapper(group);

  return {
    id: g.getId(),
    memberCount: g.getMembersCount(),
    pendingMembersCount: g.getPendingMembersCount(),
    membersName: g.getMembersName(),
    privacyLevel: g.getPrivacy(),
    getScaledLogo: (w: number, h: number) => g.getScaledLogo(w, h),
    relationship: group.relationship,
    roles: group.roles,
    title: g.getTitle(),
    joined: isJoined(group as any),
    logo: g.getLogo(),
    logoPosition: g.getLogoPosition(),
    admin: isAdmin(group as any),
  } as IGroup;
};
